import React from "react";
import * as Style from "../../styles/components/Transaction";
import logo from "../../assets/transactionLogo.png";
import currency from "../../assets/currency.png";

type Props = {
  title: string;
  description: string;
  amount: number;
};

export const Transaction: React.FC<Props> = ({
  title,
  description,
  amount,
}) => {
  return (
    <Style.Transaction>
      <Style.LeftSide>
        <Style.Logo>
          <img src={logo} alt={"logo"} />
        </Style.Logo>
        <Style.Text>
          <Style.Heading>{title}</Style.Heading>
          <Style.Description>{description}</Style.Description>
        </Style.Text>
      </Style.LeftSide>
      <Style.RightSide>
        <Style.Amount>{amount}</Style.Amount>
        <Style.Currency>
          <img src={currency} alt={"currency"} />
        </Style.Currency>
      </Style.RightSide>
    </Style.Transaction>
  );
};
