import React, { useEffect, useState, useRef, useCallback } from "react";
import { AuthSection } from "./AuthSection";
import * as Style from "../../styles/common";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as StyleCommon from "../../styles/common";

export const PhoneVerify = () => {
  const { push } = useHistory();
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState<number>();
  const [verifyCode, setVerifyCode] = useState<string>("");
  const ref = useRef();

  useEffect(() => {
    fetch("https://api.budcoin.cz/api/auth/otp/request/", {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response.json();
      })
      .then((data) => {
        console.log("data", data);
      });
  }, []);

  useEffect(() => {
    console.log("loc", location.state);
  }, [location]);

  const handleChange = useCallback(
    (e) => {
      console.log("target", e.target.value);
      setVerifyCode(e.target.value);
    },
    [verifyCode]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      console.log("code", verifyCode);
      fetch("https://api.budcoin.cz/api/auth/otp/submit/", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ code: verifyCode }),
      })
        .then((response) => {
          if (!response.ok) throw new Error();
          return response.json();
        })
        .then((data) => {
          console.log("request", data);
          push("/user");
        });
    },
    [verifyCode]
  );

  return (
    <Style.Section>
      <AuthSection heading={"Ověřte Vaše tel. číslo"} />
      <form onSubmit={handleSubmit}>
        <label htmlFor="html">SMS kód</label>
        <Style.Input>
          <input type={"text"} required onChange={handleChange} maxLength={4} />
        </Style.Input>
        <Button
          variant="primary"
          size="lg"
          className={"btn-main"}
          type={"submit"}
        >
          {"Pokračovat"}
        </Button>
      </form>
    </Style.Section>
  );
};
