import styled from "styled-components";

export const Page = styled.section`
  background: #0a58ca;
  align-items: center;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  clip-path: polygon(0 0, 100% 0, 100% 79%, 0% 100%);
  padding-bottom: 150px;

  @media screen and (min-width: 600px) {
    align-items: flex-start;
    padding-left: 100px;
  }
`;

export const Logo = styled.div`
  margin-bottom: 30px;
`;

export const MainHeading = styled.div`
  font-size: 44px;
  font-weight: 900;
  color: #000;
  margin-bottom: 20px;
`;

export const Text = styled.div`
  font-size: 26px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 40px;
  width: 70%;
  text-align: center;
  font-family: "Ubuntu";
  max-width: 350px;

  @media screen and (min-width: 600px) {
    text-align: left;
  }
`;

export const BtnText = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #0a58ca;
  font-family: "Ubuntu";
  padding: 5px 0;
`;

export const Phone = styled.div`
  display: flex;
  justify-content: center;

  > img {
    width: 100%;
    height: auto;
    max-width: 600px;
    z-index: 999;
    margin-top: -130px;
  }

  @media screen and (min-width: 600px) {
    > img {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 100px;
    }
  }
`;

export const Wrapper = styled.div`
  @media screen and (min-width: 600px) {
    display: grid;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  color: #0a58ca;
  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 100px;

  > div {
    margin: 10px 0;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    padding-left: 100px;
    margin-top: 0px;

    > div {
      margin: 150px 60px 0 0;
    }
  }
`;
