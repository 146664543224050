import styled from "styled-components";

export const Logo = styled.div`
  margin-right: 10px;
`;

export const Text = styled.div``;

export const Heading = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #000;
  font-family: "Ubuntu";
`;

export const Description = styled.div``;

export const Amount = styled.div`
  margin-right: 10px;
`;

export const Transaction = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  display: flex;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Currency = styled.div`
  > img {
    width: 10px;
    height: 10px;
  }
`;
