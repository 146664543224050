import React, { useCallback, useEffect, useState } from "react";
import * as Style from "../../styles/common";
import userIcon from "../../assets/person-circle.png";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router-dom";
import arrow from "../../assets/arrow.png";

export const Payment = () => {
  const { push } = useHistory();
  const [qrCode, setQrCode] = useState();
  const [paymentId, setPaymentId] = useState<string>();

  useEffect(() => {
    console.log("id", paymentId);
    if (paymentId) {
      setTimeout(function () {
        push("/submit", paymentId);
      }, 1000);
    }
  }, [paymentId]);

  const handleScan = useCallback((data: any) => {
    if (data) {
      setQrCode(data);
      setPaymentId(data);
    }
  }, []);

  const handleError = () => {
    console.error("Something went wrong");
  };

  return (
    <>
      <Style.SmallHeaderContainer>
        <Style.Logo>
          <Style.BackIcon>
            <img src={arrow} alt={"back"} />
          </Style.BackIcon>
          <Style.Header>Zaplatit</Style.Header>
        </Style.Logo>
        <Style.UserIcon onClick={() => push("/user")}>
          <img src={userIcon} alt="user-icon" />
        </Style.UserIcon>
      </Style.SmallHeaderContainer>

      <QrReader
        delay={100}
        onError={handleError}
        onScan={(res) => handleScan(res)}
        style={{ width: "100%" }}
      />
    </>
  );
};
