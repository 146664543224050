import React, { useCallback, useEffect, useState } from "react";
import { Header } from "../User/Header";
import { Footer } from "../Footer";
import * as Style from "../../styles/components/Body";
import event1 from "../../assets/big-event.png";
import event2 from "../../assets/big-event-2.png";
import event3 from "../../assets/big-event-3.png";
import { Transaction } from "../Transactions/Transaction";

export const Wallet = () => {
  const [showEvents, setShowEvents] = useState<boolean>(true);
  const [showTransactions, setShowTransactions] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    fetch("https://api.budcoin.cz/api/auth/users/me/", {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response.json();
      })
      .then((data) => {
        setAmount(data.wallet.balance.accountBalance);
        console.log("amount", data);
      });
  }, []);

  const handleEvents = useCallback(() => {
    setShowEvents(true);
    setShowTransactions(false);
  }, [showEvents, showTransactions]);

  const handleTransactions = useCallback(() => {
    setShowTransactions(true);
    setShowEvents(false);
  }, [showEvents, showTransactions]);
  return (
    <>
      <Header isVerified={true} amount={amount} />
      <Style.BodyContainer>
        <Style.Buttons>
          <Style.Btn onClick={handleEvents}>{"Akce"}</Style.Btn>
          <Style.Btn onClick={handleTransactions}>{"Transakce"}</Style.Btn>
        </Style.Buttons>

        {showEvents && (
          <Style.EventsContainer>
            <img src={event1} alt={"event"} />
            <img src={event2} alt={"event"} />
            <img src={event3} alt={"event"} />
          </Style.EventsContainer>
        )}
        {showTransactions ? (
          <Style.Transactions>
            <Transaction
              title={"Babiččina pekárna"}
              description={"Žižikova 12"}
              amount={25}
            />
            <Transaction
              title={"Babiččina pekárna"}
              description={"Žižikova 12"}
              amount={25}
            />
            <Transaction
              title={"Babiččina pekárna"}
              description={"Žižikova 12"}
              amount={25}
            />
            <Transaction
              title={"Babiččina pekárna"}
              description={"Žižikova 12"}
              amount={25}
            />
          </Style.Transactions>
        ) : null}
      </Style.BodyContainer>

      <Footer />
    </>
  );
};
