import React from "react";
import { useHistory } from "react-router-dom";
import * as Style from "../styles/components/Footer";
import houseIcon from "../assets/house-door.png";
import walletIcon from "../assets/wallet.png";
import payIcon from "../assets/pay.png";

export const Footer = () => {
  const { push } = useHistory();

  return (
    <Style.Wrapper>
      <Style.Item onClick={() => push("/user")}>
        <img src={houseIcon} />
        <div>{"Domů"}</div>
      </Style.Item>
      <Style.Item>
        <Style.PayIcon onClick={() => push("/payment")}>
          <img src={payIcon} />
        </Style.PayIcon>
      </Style.Item>
      <Style.Item onClick={() => push("/wallet")}>
        <img src={walletIcon} />
        <div>{"Peněženka"}</div>
      </Style.Item>
    </Style.Wrapper>
  );
};
