import React, { useState } from "react";
import * as Style from "../../styles/common";
import userIcon from "../../assets/person-circle.png";
import { Button } from "react-bootstrap";
import paymentOK from "../../assets/paymentOK.png";
import arrow from "../../assets/arrow.png";
import { useHistory } from "react-router-dom";

export const PaymentOK = () => {
  const { push } = useHistory();
  return (
    <>
      <Style.SmallHeaderContainer>
        <Style.Logo>
          <Style.BackIcon>
            <img src={arrow} alt={"back"} />
          </Style.BackIcon>
          <Style.Header>Zaplatit</Style.Header>
        </Style.Logo>
        <Style.UserIcon onClick={() => push("/user")}>
          <img src={userIcon} alt="user-icon" />
        </Style.UserIcon>
      </Style.SmallHeaderContainer>
      <Style.SubmitContainer>
        <img src={paymentOK} alt={"success"} />
        <Style.SubmitText>{"Zaplaceno"}</Style.SubmitText>
        <Button
          variant="primary"
          size="lg"
          className={"btn-main"}
          onClick={() => push("/user")}
        >
          {"OK"}
        </Button>
      </Style.SubmitContainer>
    </>
  );
};
