import React, { useEffect, useState } from "react";
import * as Style from "../../styles/common";
import { useHistory } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "./Header";
import { BodyContainer } from "./Body";

export const User = () => {
  const { push } = useHistory();
  const [amount, setAmount] = useState<number>(0);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  useEffect(() => {
    fetch("https://api.budcoin.cz/api/auth/users/me/", {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response.json();
      })
      .then((data) => {
        setAmount(data.wallet.balance.accountBalance);
        setIsVerified(data.phone_verified);
        console.log("info", data);
      });
  });

  return (
    <Style.Container>
      <Header amount={amount} isVerified={isVerified} />
      <BodyContainer />
      <Footer />
    </Style.Container>
  );
};
