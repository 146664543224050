import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as Style from "../styles/LandingPage";
import logo from "../assets/logo.png";
import phone from "../assets/phone.png";

export const LandingPage = () => {
  const { push } = useHistory();
  return (
    <Style.Wrapper>
      <Style.Page>
        <Style.Logo>
          <img src={logo} alt="logo" />
        </Style.Logo>
        <Style.Text>
          {"Aplikace s budějckou kryptoměnou podporující lokální byznys"}
        </Style.Text>
        <Button variant="light" size="lg" onClick={() => push("/home")}>
          <Style.BtnText>{"Chci platit Budcoinem"}</Style.BtnText>
        </Button>
      </Style.Page>
      <Style.Phone>
        <img src={phone} alt="phone" />
      </Style.Phone>
      <Style.Footer>
        <div>{"Začít přijímat Budcoin"}</div>
        <div>{"Stát se emitentem"}</div>
      </Style.Footer>
    </Style.Wrapper>
  );
};
