import React from "react";
import * as Style from "../../styles/components/Body";
import event1 from "../../assets/event1.png";
import event2 from "../../assets/event2.png";
import event3 from "../../assets/event3.png";
import event4 from "../../assets/event4.png";
import event5 from "../../assets/event5.png";
import { Transaction } from "../Transactions/Transaction";

type Props = {};

export const BodyContainer: React.FC<Props> = ({}) => {
  return (
    <>
      <Style.BodyContainer>
        <Style.HeaderTitle>
          <Style.Title>{"Aktuální akce"}</Style.Title>
          <Style.Link>{"Více"}</Style.Link>
        </Style.HeaderTitle>
        <Style.Events>
          <img src={event1} alt={"event1"} />
          <img src={event2} alt={"event2"} />
          <img src={event3} alt={"event3"} />
        </Style.Events>
        <Style.HeaderTitle>
          <Style.Title>{"Probíhající soutěže"}</Style.Title>
          <Style.Link>{"Více"}</Style.Link>
        </Style.HeaderTitle>
        <Style.Events>
          <img src={event4} alt={"event4"} />
          <img src={event5} alt={"event5"} />
        </Style.Events>
        <Style.HeaderTitle>
          <Style.Title>{"Transakce"}</Style.Title>
          <Style.Link>{"Více"}</Style.Link>
        </Style.HeaderTitle>

        <Transaction
          title={"Babiččina pekárna"}
          description={"Žižkovo náměstí 45/11"}
          amount={-55}
        />
        <Transaction
          title={"Město České Budějovice"}
          description={"žižkovo náměstí 45/11"}
          amount={120}
        />
      </Style.BodyContainer>
    </>
  );
};
