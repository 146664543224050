import React, { useEffect, useState } from "react";
import * as Style from "../../styles/common";
import userIcon from "../../assets/person-circle.png";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import currency from "../../assets/currencyBlue.png";
import arrow from "../../assets/arrow.png";

export const SubmitPayment = () => {
  const { push } = useHistory();
  const location = useLocation();
  const [paymentInfo, setPaymentInfo] = useState();
  const [amount, setAmount] = useState<string>();
  const [recipient, setRecipient] = useState<string>();

  useEffect(() => {
    let locState: any = location.state;
    let id = locState.split(":").pop();
    if (location.state) {
      fetch(`https://api.budcoin.cz/api/payments/${id}/`, {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          // if (!response.ok) throw new Error();
          return response.json();
        })
        .then((data) => {
          console.log("paymentdata", data);
          setPaymentInfo(data);
          setAmount(data.amount);
          setRecipient(data.recipient.name);
        });
    }
  }, []);

  const submitPayment = () => {
    let locState: any = location.state;
    let id = locState.split(":").pop();
    fetch(`https://api.budcoin.cz/api/payments/${id}/submit/`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // if (!response.ok) throw new Error();
        return response.json();
      })
      .then((data) => {
        console.log("submit", data);
        push("/success");
      });
  };
  return (
    <>
      <Style.SmallHeaderContainer>
        <Style.Logo>
          <Style.BackIcon>
            <img src={arrow} alt={"back"} />
          </Style.BackIcon>

          <Style.Header>Zaplatit</Style.Header>
        </Style.Logo>
        <Style.UserIcon onClick={() => push("/user")}>
          <img src={userIcon} alt="user-icon" />
        </Style.UserIcon>
      </Style.SmallHeaderContainer>
      <Style.SubmitContainer>
        <Style.SubmitHeading>{"Potvrdit platbu"}</Style.SubmitHeading>
        <Style.SubmitDescription>
          <Style.SubmitAmountWrapper>
            <Style.SubmitAmount>{amount}</Style.SubmitAmount>
            <img src={currency} alt={"currency"} />
          </Style.SubmitAmountWrapper>
          <Style.SubmitText>{recipient}</Style.SubmitText>
        </Style.SubmitDescription>
        <Button
          variant="primary"
          size="lg"
          className={"btn-main"}
          type={"submit"}
          onClick={submitPayment}
        >
          {"Zaplatit"}
        </Button>
      </Style.SubmitContainer>
    </>
  );
};
