import React from "react";
import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import { Home } from "./components/Auth/Home";
import { Phone } from "./components/Auth/Phone";
import { LandingPage } from "./components/LandingPage";
import { PhoneVerify } from "./components/Auth/PhoneVerify";
import { Password } from "./components/Auth/Password";
import { User } from "./components/User/User";
import { Wallet } from "./components/Wallet/Wallet";
import { Payment } from "./components/Wallet/Payment";
import { SubmitPayment } from "./components/Wallet/SubmitPayment";
import { PaymentOK } from "./components/Wallet/PaymentOK";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Switch location={location}>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/phone" exact>
          <Phone />
        </Route>
        <Route path="/phone/verify">
          <PhoneVerify />
        </Route>
        <Route path="/password">
          <Password />
        </Route>
        <Route path="/user" exact>
          <User />
        </Route>
        <Route path="/wallet">
          <Wallet />
        </Route>
        <Route path="/payment">
          <Payment />
        </Route>
        <Route path="/submit">
          <SubmitPayment />
        </Route>
        <Route path="/success">
          <PaymentOK />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
