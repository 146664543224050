import React from "react";
import * as Style from "../../styles/common";
import logo from "../../assets/logoSmall.png";
import userIcon from "../../assets/person-circle.png";
import plusIcon from "../../assets/plus-circle.png";

type Props = { amount?: number; isVerified?: boolean };

export const Header: React.FC<Props> = ({ amount, isVerified = false }) => {
  return (
    <>
      <Style.HeaderContainer>
        <Style.Nav>
          <Style.Logo>
            <img src={logo} alt="logo" />
            <Style.Header>Budcoin</Style.Header>
          </Style.Logo>

          <Style.UserIcon>
            <img src={userIcon} alt="user-icon" />
          </Style.UserIcon>
        </Style.Nav>
        <Style.AmountContainer>
          <Style.LeftSide>
            <Style.Amount>{amount ? amount : 0}</Style.Amount>
            <Style.Currency>
              <img src={logo} alt="logo" />
            </Style.Currency>
          </Style.LeftSide>

          <Style.ChargeContainer isVisible={isVerified}>
            <img src={plusIcon} alt={"plus-icon"} />
            {"dobít"}
          </Style.ChargeContainer>
        </Style.AmountContainer>
      </Style.HeaderContainer>
    </>
  );
};
