import React from "react";
import * as Style from "../../styles/common";
import * as StyleAuth from "../../styles/components/AuthSection";
import logo from "../../assets/logo.png";

type Props = {
  heading: string;
  description?: string;
};

export const AuthSection: React.FC<Props> = ({ heading, description }) => {
  return (
    <>
      <StyleAuth.LogoContainer>
        <img src={logo} alt="logo" />
      </StyleAuth.LogoContainer>
      <Style.DescriptionContainer>
        <Style.Heading>{heading}</Style.Heading>
        {description && <Style.Text>{description}</Style.Text>}
      </Style.DescriptionContainer>
    </>
  );
};
