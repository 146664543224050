import React, { useCallback, useState } from "react";
import { AuthSection } from "./AuthSection";
import * as Style from "../../styles/common";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";

export const Phone = () => {
  const { push } = useHistory();
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handleChange = useCallback(
    (e) => {
      setPhoneNumber(e.target.value);
      console.log("number", e.target.value);
    },
    [phoneNumber]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      push("/password", phoneNumber);
    },
    [phoneNumber]
  );

  return (
    <Style.Section>
      <AuthSection
        heading={"Zadejte vaše tel.číslo"}
        description={"Odešleme vám SMS kód s ověřením"}
      />
      <form onSubmit={handleSubmit}>
        <Style.Input>
          <label htmlFor="html">Telefonní číslo</label>
          <input
            type="text"
            name={"phone"}
            value={phoneNumber}
            onChange={handleChange}
            required
          />
        </Style.Input>
        <Button
          variant="primary"
          size="lg"
          className={"btn-main"}
          type={"submit"}
        >
          {"Pokračovat"}
        </Button>
      </form>
    </Style.Section>
  );
};
