import styled from "styled-components";

/**
 * Types
 */

type AvatarProps = {
  size: string;
};

type ChargeProps = {
  isVisible: boolean;
};

/**
 * Style resolvers
 */

const getSize = ({ size }: AvatarProps) => {
  switch (size) {
    case "small":
      return "140px";
    case "medium":
      return "230px";
  }
};

const getOpacity = ({ isVisible }: ChargeProps) => {
  return isVisible ? "1" : "0.3";
};

/**
 * Styled components
 */

export const Input = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > input {
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    width: 300px;
    text-align: center;
  }
`;

export const Section = styled.section`
  text-align: center;
  display: block;
  margin: 0 auto;
  //padding: 40px 20px;
  max-width: 600px;
  position: absolute;
  left: 0;
  right: 0;
`;

export const Logo = styled.div`
  padding-bottom: 200px;
  display: flex;
  position: absolute;
  top: 0;
  padding: 20px 0 0 20px;
  display: flex;
  position: absolute;
  top: 0;
  padding: 20px 0 0 20px;

  > img {
    margin-right: 15px;
  }
`;

export const Heading = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #000;
  margin-bottom: 40px;
  padding-top: 70px;
  font-family: "Ubuntu";
`;

export const BackIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  > img {
    width: 20px;
    height: 20px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 50px;
`;

export const PriceHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 70px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const LogoContainer = styled.div`
  background-color: #0a58ca;
  padding-top: 140px;
  height: 300px;
`;

export const DescriptionContainer = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
`;

export const CodeInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;

  > input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    text-align: center;
  }
`;

export const HeaderContainer = styled.div`
  background-color: #0a58ca;
  height: 200px;
`;

export const SmallHeaderContainer = styled.div`
  background-color: #0a58ca;
  height: 100px;
`;

export const Result = styled.div`
  text-align: center;
  margin-top: 100px;
`;

export const UserIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 20px 0 0;
`;

export const Header = styled.div`
  font-family: "Ubuntu";
  color: #fff;
  font-size: 24px;
  font-weight: 700;
`;

export const Amount = styled.div`
  font-family: "Ubuntu";
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  margin-right: 10px;
`;

export const AmountContainer = styled.div`
  padding: 100px 0 0 20px;
  display: flex;
      align-items: center;
      justify-content: space-between;
}
`;

export const Currency = styled.div`
  > img {
    width: 30px;
    height: 30px;
  }
`;

export const Nav = styled.div``;

export const ChargeContainer = styled.div`
  font-family: "Ubuntu";
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 20px;
  cursor: pointer;
  opacity: ${getOpacity};

  > img {
    margin-right: 10px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  position: relative;
`;

export const SubmitHeading = styled.div`
  font-family: "Ubuntu";
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 100px;
  text-align: center;
`;

export const SubmitContainer = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  > button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  > img {
    width: 130px;
    height: 130px;
    margin: 100px auto 30px auto;
  }
`;

export const SubmitText = styled.div`
  font-family: "Ubuntu";
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
`;

export const SubmitAmount = styled.div`
  font-family: "Ubuntu";
  color: #0a58ca;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-right: 10px;
`;

export const SubmitDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

export const SubmitAmountWrapper = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 30px;
    height: 30px;
  }
`;
