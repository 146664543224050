import styled from "styled-components";

export const BodyContainer = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  height: 300px;
  z-index: -1;
`;

export const HeaderTitle = styled.div`
  display: flex;
  padding: 20px 15px 0 15px;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  font-family: "Ubuntu";
`;

export const Link = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: #0a58ca;
  font-family: "Ubuntu";
`;

export const Events = styled.div`
  display: flex;
  margin-left: 15px;

  > img {
    margin: 15px 15px 0 0;
  }
`;

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  > img {
    margin-top: 20px;
  }
`;

export const Buttons = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
`;

export const Btn = styled.div`
  width: 100px;
  background: #0a58ca;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  font-family: "Ubuntu";
`;

export const Transactions = styled.div`
  padding-top: 20px;
`;
