import styled from "styled-components";

export const Wrapper = styled.div`
  height: 70px;
  width: 90%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 9.48987px 18.9797px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  position: fixed;
  bottom: 0;
  margin: 15px;
  z-index: 1;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  > img {
    width: 25px;
    height: 25px;
    margin: auto;
  }
`;

export const PayIcon = styled.div`
  padding-bottom: 30px;
`;
