import React from "react";
import { useHistory } from "react-router-dom";
import { AuthSection } from "./AuthSection";
import * as Style from "../../styles/common";
import { Button } from "react-bootstrap";

export const Home = () => {
  const { push } = useHistory();

  return (
    <>
      <Style.Section>
        <AuthSection
          heading={"Vítejte v Budcoinu"}
          description={
            "Aplikace s Budějckou kryptoměnou podporující lokální byznys"
          }
        />
        <Button
          variant="primary"
          size="lg"
          className={"btn-main"}
          onClick={() => push("/phone")}
        >
          {"Pojďme na to!"}
        </Button>
      </Style.Section>
    </>
  );
};
