import React, { useCallback, useEffect, useState } from "react";
import { AuthSection } from "./AuthSection";
import * as StyleCommon from "../../styles/common";
import { useHistory, useLocation } from "react-router-dom";
import * as Style from "../../styles/common";
import { Button } from "react-bootstrap";

export const Password = () => {
  const { push } = useHistory();
  const location = useLocation();

  const [password, setPassword] = useState<string>("");
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const handleChange = useCallback(
    (e) => {
      setPassword(e.target.value);
    },
    [password]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      fetch("https://api.budcoin.cz/api/auth/login/", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ phone: location.state, password: password }),
      }).then((response) => {
        // if (!response.ok) throw new Error();
        if (response.status === 400) {
          registerUser();
          console.log("fail");
        } else {
          push("/user");
        }
        return response.json();
      });
    },
    [password]
  );

  const registerUser = useCallback(() => {
    fetch("https://api.budcoin.cz/api/auth/users/", {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ phone: location.state, password: password }),
    })
      .then((response) => {
        if (!response.ok) console.log("chzba");
        return response.json();
      })
      .then((data) => {
        console.log("register", data);
        setPhoneNumber(data.phone);
        setIsRegistered(true);
        push("/phone/verify");
      });
  }, [password]);

  return (
    <StyleCommon.Section>
      <AuthSection heading={"Zvolte si PIN"} />

      <form onSubmit={handleSubmit}>
        <label htmlFor="html">PIN</label>
        <Style.Input>
          <input type={"text"} required onChange={handleChange} maxLength={4} />
        </Style.Input>

        <Button
          variant="primary"
          size="lg"
          className={"btn-main"}
          type={"submit"}
        >
          {"Pokračovat"}
        </Button>
      </form>
    </StyleCommon.Section>
  );
};
